import React, { useState } from "react";
import "./AllMarket.css";
import Cryptocurrency from "./Cryptocurrency/Cryptocurrency";
import Forex from "./Forex/Forex";
import Stocks from "./Stocks/Stocks";
import Metals from "./Metals/Metals";
import CFD from "./CFD/CFD";
import image from "./image.webp";
const AllMarket = () => {
  const [marketShow, setMarketShow] = useState("Cryptocurrency");

  const handleMarketShow = market => {
    switch (market) {
        case "Cryptocurrency":
            setMarketShow("Cryptocurrency");
            break;
        case "Forex":
            setMarketShow("Forex");
            break;
        case "Stocks":
            setMarketShow("Stocks");
            break;
        case "Metals":
            setMarketShow("Metals");
            break;
        case "CFD":
            setMarketShow("CFD");
            break;
    
        default:
            break;
    }
  }

  return (
    <>

<div className="user-all-market-section">
  
  <div className="user-all-market-section-img">
    <img src={image} alt="" />
  </div>
  <h5>Trading for anyone. <br />Anywhere. Anytime.</h5>
<div className="user-all-market-menu-area">
            <p onClick={()=>handleMarketShow("Cryptocurrency")}  className={marketShow === "Cryptocurrency" ? "active" : ""}>Cryptocurrency</p>
            <p onClick={()=>handleMarketShow("Metals")}  className={marketShow === "Metals"  ? "active" : ""}>Commodities</p>
            <p onClick={()=>handleMarketShow("CFD")}  className={marketShow === "CFD"  ? "active" : ""}>CFDs</p>
            <p onClick={()=>handleMarketShow("Forex")}  className={marketShow === "Forex" ? "active" : ""}>Forex</p>
            <p onClick={()=>handleMarketShow("Stocks")}  className={marketShow === "Stocks"  ? "active" : ""}>Stocks</p>
        </div>

</div>


      <section className="container">
      <div className="user-all-market-data">
      {marketShow === "Cryptocurrency" && <Cryptocurrency />}
        {marketShow === "Forex" && <Forex />}
        {marketShow === "Stocks" && <Stocks />}
        {marketShow === "Metals" && <Metals />}
        {marketShow === "CFD" && <CFD />}
      </div>
      </section>
    </>
  );
};

export default AllMarket;
