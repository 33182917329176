import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";
import { useNavigate } from "react-router-dom";

const LogoutConponent = () => {
  const { LoginWithEmail, setUser } = useContext(AuthContext);
  const from = "/login";
  const navigate = useNavigate();
  const [remove, setremove] = useState(false);
  const SignOut = () => {
    document.cookie = `robicon_token=; expires=${new Date(
      0
    ).toUTCString()}; path=/`;
    localStorage.removeItem("robicon_id");
    const data = null;
    LoginWithEmail(data);
    setUser(null);
    setremove(true);
  };

  useEffect(() => {
    if (remove) {
      navigate(from, { replace: true });
    }
  }, [remove, navigate]);

  return (
    <>
      <p onClick={SignOut}>Logout</p>
    </>
  );
};

export default LogoutConponent;
