import React from "react";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";
const ListsSingleItem = ({data}) => {
  return (
    <>
      <div className="news-lists-item">
        <img
          src={`${process.env.REACT_APP_API}/${data?.image}`}
          alt=""
        />
        <div className="news-content">
          <h4>
           {data?.title}
          </h4>
          <p>
          {data?.dis}
          </p>
          <span>Posted On  {dateFormat(data?.createdAt, "mmmm dd, yyyy")}</span>
          <Link to={`/user/news/details/${data?._id}`}>Read More</Link>
        </div>
      </div>
    </>
  );
};

export default ListsSingleItem;
