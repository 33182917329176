import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../../../Contexts/AuthContext/AuthProvider";
import SingleItem from "./SingleItem";
import { toast } from "react-toastify";

const PositionHolding = () => {
  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const[loading, setLoading] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 1000);
    return () => clearInterval(interval);
  }, [data]);

  const fetchData = async () => {
    if (authUser?._id) {
      const data = await fetch(
        `${process.env.REACT_APP_API}/api/user/trade/log/running/history/${authUser?._id}`
      );
      const apiResponse = await data.json();
      setData(apiResponse?.data);
      if(apiResponse){
        setLoading(false);
      }
    }
  };

  const [DisId, setDisId] = useState(null);
  
const HandleClose = (id) =>{
  setDisId(id);
  if(id){
      fetch(
          `${process.env.REACT_APP_API}/api/user/trade/log/close/possition/${id}`,
          {
            method: "PUT",
          }
        )
          .then((res) => res.json())
          .then((data) => {
              if(data?.success){
                  toast.error(`${data?.message}`, {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "colored",
                    });
              }
          });

  }

}


  if(loading){
    return;
  }

  return (
    <>
      <div className="trade-order-history-area">
        <table>
          <thead>
            <tr>
              <th>Pairs</th>
              <th>Transaction</th>
              <th>Direction</th>
              <th>Lots</th>
              <th>Open price</th>
              <th>Current price</th>
              <th>Take Profit</th>
              <th>Set Loss</th>
              <th>Handling fee</th>
              <th>Margin</th>
              <th>Profit</th>
              <th>Open time</th>
              <th>Operation</th>
            </tr>
          </thead>
          <tbody>
            {data?.length !== 0 ? (
              data.map((data, index) => {
                if (data) {
                  return (
                    <SingleItem
                      data={data}
                      index={index}
                      key={data?._id}
                      HandleClose={HandleClose}
                      DisId={DisId}
                    ></SingleItem>
                  );
                }
              })
            ) : (
              <tr>
                <td className="text-muted text-center" colSpan="100%">
                  No records available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PositionHolding;
