import React, { useContext } from "react";
import "./Assets.css";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import bitcoin from "./bitcoin_btc.svg";
import gold from "./gold.jpeg";
import ETH from "./eth.svg";
import solona from "./solona.png";
import USDT from "./usdt.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Assets = () => {
  const { authUser } = useContext(AuthContext);
  return (
    <>
      <div className="container">
        <div className="assets">
          <div className="assets-card">
            <div className="assets-wave"></div>
            <div className="assets-wave"></div>
            <div className="assets-wave"></div>
            <div className="assets-content">
              <div className="assets-balance-area">
                <h5>Available Balance: </h5>
                <span>{authUser?.balance}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="assets-coin-title-area">
          <h6>Your Coin Asset</h6>
        </div>
        <div className="assets">
          <div className="assets-card">
            <div className="assets-wave"></div>
            <div className="assets-wave"></div>
            <div className="assets-wave"></div>
            <div className="assets-coin-area">
              <div className="assets-coin">
                <LazyLoadImage alt="bitcoin" effect="blur" src={bitcoin} />
                <div className="assets-coin-name">
                  <h5>BTC</h5>
                  <p>0</p>
                  <span>000</span>
                </div>
              </div>
              <div className="assets-coin-percentage">
                <p>0.00%</p>
              </div>
            </div>
          </div>
        </div>
        <div className="assets">
          <div className="assets-card">
            <div className="assets-wave"></div>
            <div className="assets-wave"></div>
            <div className="assets-wave"></div>
            <div className="assets-coin-area">
              <div className="assets-coin">
                <LazyLoadImage alt="gold" effect="blur" src={gold} />
                <div className="assets-coin-name">
                  <h5>Gold</h5>
                  <p>0</p>
                  <span>000</span>
                </div>
              </div>
              <div className="assets-coin-percentage">
                <p>0.00%</p>
              </div>
            </div>
          </div>
        </div>
        <div className="assets">
          <div className="assets-card">
            <div className="assets-wave"></div>
            <div className="assets-wave"></div>
            <div className="assets-wave"></div>
            <div className="assets-coin-area">
              <div className="assets-coin">
                <LazyLoadImage alt="ETH" effect="blur" src={ETH} />
                <div className="assets-coin-name">
                  <h5>ETH</h5>
                  <p>0</p>
                  <span>000</span>
                </div>
              </div>
              <div className="assets-coin-percentage">
                <p>0.00%</p>
              </div>
            </div>
          </div>
        </div>
        <div className="assets">
          <div className="assets-card">
            <div className="assets-wave"></div>
            <div className="assets-wave"></div>
            <div className="assets-wave"></div>
            <div className="assets-coin-area">
              <div className="assets-coin">
                <LazyLoadImage alt="USDT" effect="blur" src={USDT} />
                <div className="assets-coin-name">
                  <h5>USDT</h5>
                  <p>0</p>
                  <span>000</span>
                </div>
              </div>
              <div className="assets-coin-percentage">
                <p>0.00%</p>
              </div>
            </div>
          </div>
        </div>
        <div className="assets">
          <div className="assets-card">
            <div className="assets-wave"></div>
            <div className="assets-wave"></div>
            <div className="assets-wave"></div>
            <div className="assets-coin-area">
              <div className="assets-coin">
                <LazyLoadImage alt="solona" effect="blur" src={solona} />
                <div className="assets-coin-name">
                  <h5>Solana</h5>
                  <p>0</p>
                  <span>000</span>
                </div>
              </div>
              <div className="assets-coin-percentage">
                <p>0.00%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Assets;
