import React, { useContext, useEffect, useRef, useState } from "react";
import "./BitcoinMining.css";
import SingleItem from "./SingleItem";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";
import { toast } from "react-toastify";
import axios from "axios";
import CustomPagination from "../../CustomPagination/CustomPagination";
import HistoryItem from "./HistoryItem";
const BitcoinMining = () => {
  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/user/mining/bitcoin/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data);
        setLoading(false);
      });
  }, []);

  // Store data

  const refSubmitDis = useRef();
  const [updateData, setUpdateData] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);

  const handleSubmitData = async (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);
    setErrorMessage();
    try {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      await axios
        .post(
          `${process.env.REACT_APP_API}/api/user/mining/store`,
          { user_id: authUser?._id, ...InpuData },
          config
        )
        .then((data) => {
          setUpdateData(data);
          toast.success(`${data.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          refSubmitDis.current.removeAttribute("disabled");
          event.target.reset();
        })
        .catch((error) => {
          setErrorMessage({ id: InpuData?.id, ...error?.response?.data });
          refSubmitDis.current.removeAttribute("disabled");
        });
    } catch (error) {
      console.log(error);
    }
  };

  // history

  const [HistoryData, setHistoryData] = useState([]);
  const [paginateData, setPaginateData] = useState([]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API}/api/user/mining/bitcoin/history/view/${authUser?._id}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setHistoryData(data?.data);
        setPaginateData(data);
      });
  }, []);

  const handlePage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `${process.env.REACT_APP_API}/api/user/mining/bitcoin/history/view/${authUser?._id}?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setHistoryData(response?.data?.data);
          setPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };

  // calcultor

  const [calData, setCalData] = useState([]);
  const [DailyProfit, setDailyProfit] = useState(0.0);
  const [TotalProfit, setTotalProfit] = useState(0.0);
  const [dataValue, setDataValue] = useState({});
  const handleSelect = (e) => {
    if (e.target.value) {
      fetch(
        `${process.env.REACT_APP_API}/api/user/mining/view/${e.target.value}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (dataValue?.amount) {
            if (
              parseFloat(dataValue?.amount) < parseFloat(data?.data?.min_amount)
            ) {
              setDailyProfit(`min:$${data?.data?.min_amount}`);
              setTotalProfit(`min:$${data?.data?.min_amount}`);
            } else if (
              parseFloat(dataValue?.amount) > parseFloat(data?.data?.max_amount)
            ) {
              setDailyProfit(`max:$${data?.data?.max_amount}`);
              setTotalProfit(`max:$${data?.data?.max_amount}`);
            }
            if (data?.data?.principal === 1) {
              const percentage = parseFloat(data?.data?.percentage);
              const Dailysum =
                (parseFloat(dataValue?.amount) * parseFloat(percentage)) / 100;
              const TotalProfitsum =
                parseFloat(Dailysum) * parseFloat(data?.data?.period) +
                parseFloat(dataValue?.amount);
              setDailyProfit(Dailysum);
              setTotalProfit(TotalProfitsum);
            } else {
              const percentage = parseFloat(data?.data?.percentage);
              const Dailysum =
                (parseFloat(dataValue?.amount) * parseFloat(percentage)) / 100 -
                parseFloat(dataValue?.amount);
              const TotalProfitsum =
                parseFloat(Dailysum) * parseFloat(data?.data?.period) +
                parseFloat(dataValue?.amount);
              setDailyProfit(Dailysum.toFixed(2));
              setTotalProfit(TotalProfitsum.toFixed(2));
            }

            setCalData(data?.data);
          } else {
            setCalData(data?.data);
          }
        });
    }
  };

  const handleInputBlur = (event) => {
    if (calData?._id) {
      const value = event.target.value;
      const field = event.target.name;
      if (parseFloat(value) < parseFloat(calData?.min_amount)) {
        setDailyProfit(`min:$${calData?.min_amount}`);
        setTotalProfit(`min:$${calData?.min_amount}`);
      } else if (parseFloat(value) > parseFloat(calData?.max_amount)) {
        setDailyProfit(`max:$${calData?.max_amount}`);
        setTotalProfit(`max:$${calData?.max_amount}`);
      } else if (calData?.principal === 1) {
        const percentage = parseFloat(calData?.percentage);
        const Dailysum = (parseFloat(value) * parseFloat(percentage)) / 100;
        const TotalProfitsum =
          parseFloat(Dailysum) * parseFloat(calData?.period) +
          parseFloat(value);
        setDailyProfit(Dailysum);
        setTotalProfit(TotalProfitsum);
      } else {
        const percentage = parseFloat(calData?.percentage);
        const Dailysum =
          (parseFloat(value) * parseFloat(percentage)) / 100 -
          parseFloat(value);
        const TotalProfitsum =
          parseFloat(Dailysum) * parseFloat(calData?.period) +
          parseFloat(value);
        setDailyProfit(Dailysum.toFixed(2));
        setTotalProfit(TotalProfitsum.toFixed(2));
      }
      const newUser = { ...dataValue };
      newUser[field] = value;
      setDataValue(newUser);
    } else {
      const value = event.target.value;
      const field = event.target.name;
      const newUser = { ...dataValue };
      newUser[field] = value;
      setDataValue(newUser);
    }
  };

  return (
    <>
      <section className="container">
        <div class="mining-area">
          <div class="row gy-5">
            {/* <SingleItem /> */}
            {data?.length !== 0 ? (
              data.map((data, index) => {
                if (data) {
                  return (
                    <SingleItem
                      data={data}
                      index={index}
                      key={data._id}
                      handleSubmitData={handleSubmitData}
                      refSubmitDis={refSubmitDis}
                      errorMessage={errorMessage}
                    ></SingleItem>
                  );
                }
              })
            ) : (
              <>
                <div className="text-center h-50">
                  <h6> Data not found</h6>
                </div>
              </>
            )}
          </div>

          <div className="row pb-5">
            <div className="calci_bg">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-4">
                    <ul className="calci">
                      <li>
                        <div className="cal_text">
                          <h3>
                            Select Your Plan
                            <select
                              value={calData?._id}
                              onChange={handleSelect}
                              id="Ultra"
                            >
                              <option selected>Select Now</option>
                              {data.map((data, idx) => {
                                return (
                                  <option key={data?._id} value={data?._id}>
                                    {data.promotion_name}
                                  </option>
                                );
                              })}
                            </select>
                          </h3>
                        </div>
                      </li>
                      <li>
                        <div className="cal_text">
                          <h3>
                            Enter Your Amount
                            <input
                              type="number"
                              className="inpts"
                              name="amount"
                              onChange={handleInputBlur}
                              id="money"
                            />
                          </h3>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="col-lg-4">
                    <ul className="calci1">
                      <li>
                        <div className="cal_text">
                          <h3>
                            Daily Profit
                            <span id="profitDaily">{DailyProfit}</span>
                          </h3>
                        </div>
                      </li>
                      <li>
                        <div className="cal_text">
                          <h3>
                            Total Profit
                            <span className="cl1" id="profitTotal">
                              {TotalProfit}
                            </span>
                          </h3>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="col-lg-4">
                    <div className="text_but text-center">
                      <button
                        type="submit"
                        className="but1 hvr-shutter-in-horizontal"
                      >
                        Calculate Now <i className="ri-play-fill"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* History  */}
          <div className="user-data-table">
            <div className="col-xxl-12 col-md-12 pt-5">
              <div className=" text-white card ">
                <div className="card-header d-flex flex-wrap justify-content-between">
                  <h4 className="title text-primary">My Investemt</h4>
                </div>
                <div className="card-body  text-white p-2">
                  <div className="text-white table-responsive ">
                    <table className="text-white table table-responsive-md ">
                      <thead>
                        <tr className=" mx-auto table-header">
                          <th>Invest Date</th>
                          <th>Invest Amount</th>
                          <th>Total Return</th>
                          <th>C.Profit</th>
                          <th>Remaining</th>
                          <th>End At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {HistoryData?.length !== 0 ? (
                          HistoryData.map((data, index) => {
                            if (data) {
                              return (
                                <HistoryItem
                                  data={data}
                                  index={index}
                                  key={data?._id}
                                  paginateData={paginateData}
                                ></HistoryItem>
                              );
                            }
                          })
                        ) : (
                          <tr>
                            <td
                              className="text-muted text-center"
                              colSpan="100%"
                            >
                              No records available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* paginateLinks */}
                  <CustomPagination
                    data={paginateData}
                    handlePage={handlePage}
                  ></CustomPagination>
                  {/* paginateLinks */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BitcoinMining;
