import React, { useEffect } from "react";
import FinancialExchange from "./FinancialExchange/FinancialExchange";
import BuiltOn from "./BuiltOn/BuiltOn";
import TradeApp from "./TradeApp/TradeApp";
import OurMission from "./OurMission/OurMission";
import TradingPlatfrom from "./TradingPlatfrom/TradingPlatfrom";
import EarningToday from "./EarningToday/EarningToday";
import MoreControl from "./MoreControl/MoreControl";
import Customers from "./Customers/Customers";

const Home = () => { 

  useEffect(() => {
    const options = {
      headers: {
        'x-access-token': 'coinrankingb6957b691ed71fd25a696e5fe58f7f660d05e8b26f50a619',
      },
    };
    
    fetch('https://api.coinranking.com/v2/coin/Qwsogvtv82FCd/price', options)
    .then((response) => response.json())
    .then((result) => console.log(result?.data));


}, [])


  return (
    <>
      <FinancialExchange />
      <BuiltOn />
      <TradeApp />
      <OurMission />
      <MoreControl />
      <Customers />
      <TradingPlatfrom />
      <EarningToday />
    </>
  );
};

export default Home;
