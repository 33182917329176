import React from "react";
import Countdown from "react-countdown";
const SingleItem = ({ data, handleSubmitData, refSubmitDis, errorMessage }) => {
  const rendererTime = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return;
    } else {
      return (
        <>
          <div className="countdown-timer" id="countdown-24">
            <div className="days">{days}d</div>
            <div className="hours">{hours}h</div>
            <div className="minutes">{minutes}m </div>
            <div className="seconds">{seconds}s</div>
          </div>
        </>
      );
    }
  };
  // console.log(errorMessage?.id === data?._id)
  return (
    <>
      <div className="col-3">
        <div className="card-custom">
          <div className="badge-custom2"></div>
          <div className="badge-custom">{data?.percentage}%</div>
          <h5 className="mt-3">
            {data?.instant === 1 ? (
              <>
                <b>
                  <span style={{ color: "gold" }}>Daily for </span>
                </b>
                <span>
                  {`${data?.period}`} {`${data?.period_type}`}
                </span>
              </>
            ) : (
              <>
                <b>
                  <span style={{ color: "gold" }}>AFTER for</span>
                </b>
                <span>
                  {`${data?.period}`} {`${data?.period_type}`}
                </span>
              </>
            )}
          </h5>
          <div className="d-flex flex-row-reverse">
            <div className="text-box">{data?.promotion_name} </div>
          </div>
          <div className="investment-info">
            <p>Min Deposit: ${data?.min_amount}</p>
            <p>Max Deposit: ${data?.max_amount}</p>
            <p>
              Principal:
              {data?.principal === 1 ? (
                <span> Returns</span>
              ) : data?.principal === 2 ? (
                <span> Included</span>
              ) : (
                ""
              )}
            </p>
          </div>
          <div className="countdown">
            <Countdown date={data?.expired_time} renderer={rendererTime} />
          </div>
          <form onSubmit={handleSubmitData}>
            <label className="mt-2">Amount</label>
            <input type="hidden" name="id" value={data?._id} />
            <input
              name="amount"
              className="form-control"
              placeholder={`Enter amount Eg. ${data?.min_amount}`}
              required=""
              id="amount"
            />
            {errorMessage?.id && errorMessage?.id === data?._id && (
              <>
                {errorMessage?.errors?.amount && (
                  <span className="text-danger pt-2">
                    {errorMessage?.errors?.amount?.msg}
                  </span>
                )}

                {errorMessage?.message && (
                  <span className="text-danger pt-2">
                    {errorMessage?.message}
                  </span>
                )}
              </>
            )}
            <div className="pt-3">
              <button
                type="submit"
                className="but1 hvr-shutter-in-horizontal"
                ref={refSubmitDis}
              >
                  Start Mining
                <i className="ri-play-fill"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SingleItem;
