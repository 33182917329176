import React, { useContext, useEffect, useState } from "react";
import "./InvestmentPlan.css";
import SingleItem from "./SingleItem";
import InvestModal from "./InvestModal/InvestModal";
import axios from "axios";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import CustomPagination from "../CustomPagination/CustomPagination";
import HistoryItem from "./HistoryItem";

const InvestmentPlan = () => {
  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/user/investment/plan/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data);
        setLoading(false);
      });
  }, []);

  const [handleModalShow, setHandleModalShow] = useState(false);
  const [Modalshow, setModalshow] = useState(true);
  const [SingleData, setSingleData] = useState([]);

  const handleModal = (id) => {
    if (id) {
      fetch(
        `${process.env.REACT_APP_API}/api/user/investment/plan/view/${id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setSingleData(data?.data);
          setModalshow(true);
        });
      setHandleModalShow(true);
    }
  };

  // history

  const [HistoryData, setHistoryData] = useState([]);
  const [paginateData, setPaginateData] = useState([]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API}/api/user/investment/plan/history/view/${authUser?._id}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setHistoryData(data?.data);
        setPaginateData(data);
      });
  }, []);

  const handlePage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `${process.env.REACT_APP_API}/api/user/investment/plan/history/view/${authUser?._id}?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setHistoryData(response?.data?.data);
          setPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {handleModalShow && (
        <>
          <InvestModal
            className="posce-trade-modal"
            show={Modalshow}
            singledata={SingleData}
            onHide={() => setModalshow(false)}
          />
        </>
      )}

      <section className="investment-plan-section">
        <div className="planwrap">
          <div className="container">
            <div className="investment-plan-title">
              <h4>OUR INVESTMENT PLANS</h4>
            </div>
            <div className="demo">
              <div className="row gy-3">
                {data?.length !== 0 ? (
                  data.map((data, index) => {
                    if (data) {
                      return (
                        <SingleItem
                          data={data}
                          index={index}
                          key={data._id}
                          handleModal={handleModal}
                        ></SingleItem>
                      );
                    }
                  })
                ) : (
                  <>
                    <div className="text-center h-50">
                      <h6> Data not found</h6>
                    </div>
                  </>
                )}
              </div>
              {/* History  */}
              <div className="user-data-table">
                <div className="col-xxl-12 col-md-12 pt-5">
                  <div className=" text-white card ">
                    <div className="card-header d-flex flex-wrap justify-content-between">
                      <h4 className="title text-primary">My Investemt Plan</h4>
                    </div>
                    <div className="card-body  text-white p-2">
                      <div className="text-white table-responsive ">
                        <table className="text-white table table-responsive-md ">
                          <thead>
                            <tr className=" mx-auto table-header">
                              <th>Invest Date</th>
                              <th>Invest Amount</th>
                              <th>Total Return</th>
                              <th>C.Profit</th>
                              <th>Remaining</th>
                              <th>End At</th>
                            </tr>
                          </thead>
                          <tbody>
                            {HistoryData?.length !== 0 ? (
                              HistoryData.map((data, index) => {
                                if (data) {
                                  return (
                                    <HistoryItem
                                      data={data}
                                      index={index}
                                      key={data?._id}
                                      paginateData={paginateData}
                                    ></HistoryItem>
                                  );
                                }
                              })
                            ) : (
                              <tr>
                                <td
                                  className="text-muted text-center"
                                  colSpan="100%"
                                >
                                  No records available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      {/* paginateLinks */}
                      <CustomPagination
                        data={paginateData}
                        handlePage={handlePage}
                      ></CustomPagination>
                      {/* paginateLinks */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InvestmentPlan;
