import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SingleItem = ({ data, livePrice, index, handleTradeChart }) => {
  const MarketSymbol = (data?.Symbol)+(data?.currency);
  return (
    <>
      <div className="flex py-14 cursor-pointer items-center hover:bg-gray-100 px-10 bottom-border" onClick={()=>handleTradeChart(MarketSymbol)}>
        <div className="flex-1 flex items-center">
          <LazyLoadImage
            alt="img"
            effect="blur"
            className="w-36 h-36"
            src={`${process.env.REACT_APP_API}/${data?.image}`}
          />

          <span className="pl-10">
            {data?.Symbol}
            {data?.currency}T
          </span>
        </div>
        <div className="flex-1 text-kline-up">{livePrice[index]?.bid}</div>
        {/* <span className="rounded-8 text-white w-80 text-center py-6 text-12 bg-kline-up">
          +0.21%
        </span> */}
      </div>
    </>
  );
};

export default SingleItem;
