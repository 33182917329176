import React, { useEffect, useState } from 'react';
import SingleItem from './SingleItem';

const Crypto = ({handleTradeChart}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API}/api/user/trade/market/crypto/currency/view`, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(data => {
                if(data?.success){
                    setData(data?.data);
                }
                setLoading(false);
            });


    }, [])

    
    const SymbolString = data.reduce(
        (acc, curr) => `${acc}${curr.Symbol}${curr.currency},`,
        ""
      );
      const Symbol = SymbolString.substring(0, SymbolString?.length - 1);
    
      const [livePrice, setLivePrice] = useState([]);
    
      useEffect(() => {
        fetchData();
      }, [data]);

      const fetchData = async () => {
        if (Symbol?.length !== 0) {
          const data = await fetch(
            `https://marketdata.tradermade.com/api/v1/live?currency=${Symbol}T&api_key=${process.env.REACT_APP_TRADE_API_KEY}`
          );
          const apiResponse = await data.json();
          setLivePrice(apiResponse?.quotes);
        }
      };

      
      if(loading){
        return;
      }
    return (
        <>
              <div className="dashboard-trade-market-area  h-700 overflow-auto element">
                
            {data?.length !== 0 ? (
                            data.map((data, index) => {
                              if (data) {
                                return (
                                  <SingleItem
                                    data={data}
                                    index={index}
                                    key={data._id}
                                    livePrice={livePrice}
                                    handleTradeChart={handleTradeChart}
                                  ></SingleItem>
                                );
                              }
                            })
                          ) : (
                            <>
                              <div className="text-center h-50">
                                <h6> Data not found</h6>
                              </div>
                            </>
                          )}

          </div> 
        </>
    );
};

export default Crypto;