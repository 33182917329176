import React, { useEffect, useRef, memo } from "react";
function Cryptocurrency() {
  const container = useRef({});
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-market-quotes.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
          {
  "title": "Cryptocurrencies",
  "title_raw": "Cryptocurrencies",
  "width": "100%",
  "height": "100%",
  "locale": "en",
  "showSymbolLogo": true,
  "symbolsGroups": [
    {
      "symbols": [
        {
          "name": "BTCUSDT",
            "displayName": "Bitcoin/USDT"
        },
        {
          "name": "ETHUSDT",
            "displayName": "Ethereum/USDT"
        },
         {
          "name": "SOLUSDT",
            "displayName": "Solana/USDT"
        },
        {
          "name": "USDTUSDC",
            "displayName": "Tethe/USDC"
        },
        {
          "name": "BNBUSDT",
            "displayName": "BNB coin/USDT"
        },
        {
          "name": "DOGEUSDT",
            "displayName": "DogeCoin/USDT"
        },
        {
          "name": "TRXUSDT",
            "displayName": "Tron/USDT"
        },
        {
          "name": "XRPUSDT",
            "displayName": "XRP/USDT"
        },
        {
          "name": "USDCUSDT",
            "displayName": "USDC/USDT"
        },
        {
          "name": "SHIBUSDT",
            "displayName": "SHIB/USDT"
        }
        
      ]
    }
  ],
   "showSymbolLogo": true,
  "isTransparent": false,
  "colorTheme": "dark",
  "locale": "en",
  "backgroundColor": "#0B0619"
}
`;
    container.current.appendChild(script);
  }, []);

  return (
    <>
        <div className="user-market-data">
          <div
            className="tradingview-widget-container"
            ref={container}
            style={{ height: "100%", width: "100%" }}
          >
            <div
              className="tradingview-widget-container__widget"
              style={{ height: "calc(100% - 32px)", width: "100%" }}
            ></div>
          </div>
        </div>
    </>
  );
}

export default memo(Cryptocurrency);
