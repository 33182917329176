import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../../Contexts/AuthContext/AuthProvider";
import SingleItem from "./SingleItem";
import { toast } from "react-toastify";

const PendingOrders = () => {
    const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const[updateData, setUpdateData] = useState([]);
  const[loading, setLoading] = useState(true);

  useEffect(() => {
    if(authUser?._id){
        fetch(
            `${process.env.REACT_APP_API}/api/user/trade/log/pending/history/${authUser?._id}`,
            {
              method: "GET",
            }
          )
            .then((res) => res.json())
            .then((data) => {
                setData(data?.data);
                console.log(data?.data)
                setLoading(false);
            });
    }
  }, [updateData, authUser?._id]);

  const [DisId, setDisId] = useState(null);

const HandleClose = (id) =>{
    setDisId(id);
    if(id){
        fetch(
            `${process.env.REACT_APP_API}/api/user/trade/log/close/order/${id}`,
            {
              method: "PUT",
            }
          )
            .then((res) => res.json())
            .then((data) => {
              setUpdateData(data?.data);
                if(data?.success){
                    toast.error(`${data?.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                      });
                }

            });

    }

}



  if(loading){
    return;
  }
  
  return (
    <>
      <div className="trade-order-history-area">
        <table>
          <thead>
            <tr>
              <th>Pairs</th>
              <th>Transaction</th>
              <th>Direction</th>
              <th>Lots</th>
              <th>Open price</th>
              <th>Current price</th>
              <th>Take Profit</th>
              <th>Set Loss</th>
              <th>Handling fee</th>
              <th>Profit</th>
              <th>Open time</th>
              <th>Operation</th>
            </tr>
          </thead>
          <tbody>
            {data?.length !== 0 ? (
              data.map((data, index) => {
                if (data) {
                  return (
                    <SingleItem
                      data={data}
                      index={index}
                      key={data?._id}
                      HandleClose={HandleClose}
                      DisId={DisId}
                    ></SingleItem>
                  );
                }
              })
            ) : (
              <tr>
                <td className="text-muted text-center" colSpan="100%">
                  No records available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PendingOrders;