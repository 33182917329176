import React, {  useState } from "react";
import logo2 from "../../../../Logo.jpg";
import {  NavLink } from "react-router-dom";
import "./UserMenu.css";
import image from "./user.png";
import UserModalMenu from "../UserModalMenu/UserModalMenu";
const UserMenu = () => {
  const [modalShow, setModalShow] = useState(false);


  return (
    <>
      <nav className="user-manu-area">
        <div className="container">
          <div className="user-manu-items">
            <div className="user-manu-logo">
              <NavLink className="logo" to="/">
                <img src={logo2} alt="" />
              </NavLink>
            </div>

            <div className="user-menu">
              <ul className="nav-items d-flex align-items-center m-0 justify-content-between ">
                <li className="nav-item">
                  <NavLink to="/user/all/market" data-text="&nbsp;Market">
                    &nbsp;Market&nbsp;
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/user/trade" data-text="&nbsp;Trade">
                    &nbsp;Trade&nbsp;
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/user/bitcoin/mining"
                    data-text="&nbsp;Bitcoin&nbsp;Mining"
                  >
                    &nbsp;Bitcoin&nbsp;Mining&nbsp;
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/user/gold/mining"
                    data-text="&nbsp;Gold&nbsp;Mining"
                  >
                    &nbsp;Gold&nbsp;Mining&nbsp;
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/user/investment/plan"
                    data-text="&nbsp;Investment&nbsp;Plan"
                  >
                    &nbsp;Investment&nbsp;Plan&nbsp;
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/user/apply/loan" data-text="&nbsp;Loans">
                    &nbsp;Loans&nbsp;
                  </NavLink>
                </li>
                <li className="nav-item" end>
                  <NavLink to="/user/news" data-text="&nbsp;News">
                    &nbsp;News&nbsp;
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="user-avatar">
              <img src={image} alt="" onClick={() => setModalShow(true)} />
            </div>
          </div>
        </div>
      </nav>

      {/* <header>
        <div className="Navbar___StyledPopover-sc-19dqj0b-20 cFGboa">
          <div
            className="Navbar__NavWrapper-sc-19dqj0b-1 iitcUi"
            style={{
              backgroundColor: navColor,
              transition: "all 0.5s",
            }}
          >
            <div className="Navbar___StyledDiv-sc-19dqj0b-14 kJIlUY">
              <NavLink to="/">
                <span className="Navbar___StyledSpan2-sc-19dqj0b-15 gdxWox">
                  Rubicon
                </span>
                <img
                  src={logo2}
                  alt=""
                  className="Navbar___StyledImg-sc-19dqj0b-16 iitSla"
                />
              </NavLink>
            </div>
            <div className="Navbar___StyledDiv3-sc-19dqj0b-21 ighICX desktop-menu">
              <nav className="Navbar___StyledPopoverGroup-sc-19dqj0b-22 idhwIs">
                <NavLink
                  to="/user/all/market"
                  className="Navbar__NavbarLinkWrapper-sc-19dqj0b-2 bjtaVo"
                >
                  Market
                </NavLink>
                <NavLink
                  to="/user/trade"
                  className="Navbar__NavbarLinkWrapper-sc-19dqj0b-2 bjtaVo"
                >
                  Trade
                </NavLink>

                <NavLink
                  to="/user/bitcoin/mining"
                  className="Navbar__NavbarLinkWrapper-sc-19dqj0b-2 bjtaVo"
                >
                  Bitcoin Mining
                </NavLink>

                <NavLink
                  to="/user/gold/mining"
                  className="Navbar__NavbarLinkWrapper-sc-19dqj0b-2 bjtaVo"
                >
                  Gold Mining
                </NavLink>
                <NavLink
                  to="/user/investment/plan"
                  className="Navbar__NavbarLinkWrapper-sc-19dqj0b-2 bjtaVo"
                >
                  Investment Plan
                </NavLink>
                <NavLink
                  to="/user/apply/loan"
                  className="Navbar__NavbarLinkWrapper-sc-19dqj0b-2 bjtaVo"
                >
                  Loans
                </NavLink>
                <NavLink
                  to=""
                  className="Navbar__NavbarLinkWrapper-sc-19dqj0b-2 bjtaVo"
                >
                  News
                </NavLink>
              </nav>
            </div>
          </div>
        </div>
      </header> */}

<UserModalMenu show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default UserMenu;
