import React, { useContext, useEffect, useState } from "react";
import "./Dashboard.css";
import TradingViewWidget from "../../TradingViewWidget/TradingViewWidget";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import Market from "../Market/Market";
import TradePage from "../TradePage/TradePage";
import "./TradeHistory.css";
import PositionHolding from "./TradeHistory/PositionHolding/PositionHolding";
import PendingOrders from "./TradeHistory/PendingOrders/PendingOrders";
import History from "./TradeHistory/History/History";

const Dashboard = () => {
  const { authUser } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [marketSymbol, setMarketSymbol] = useState("BTCUSD");
  const handleTradeChart = (symbol) => {
    setLoading(true);
    setMarketSymbol(symbol);
    setTimeout(function () {
      setLoading(false);
    }, 800);
  };

  const [historyShow, setHistoryShow] = useState("PositionHolding");

  const handleHistory = (data) => {
    switch (data) {
      case "PositionHolding":
        setHistoryShow("PositionHolding");
        break;
      case "PendingOrders":
        setHistoryShow("PendingOrders");
        break;
      case "History":
        setHistoryShow("History");
        break;

      default:
        break;
    }
  };


  const [data, setData] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 1000);
    return () => clearInterval(interval);
  }, [data]);

  const fetchData = async () => {
    if (authUser?._id) {
      const data = await fetch(
        `${process.env.REACT_APP_API}/api/user/trade/log/profit/loss/${authUser?._id}`
      );
      const apiResponse = await data.json();
      setData(apiResponse?.data);
    }
  };

  return (
    <>
      <section className="user-dashboard-section">
        <div className="row">
          <div className="col-3">
            <Market handleTradeChart={handleTradeChart} />
          </div>
          {loading ? (
            ""
          ) : (
            <>
              <div className="col-6">
                <TradingViewWidget marketSymbol={marketSymbol} />
              </div>
              <div className="col-3">
                <TradePage marketSymbol={marketSymbol} />
              </div>
            </>
          )}
        </div>
        <section className="trade-order-history-section">
          <div className="trade-order-history-menu-area">
            <div className="trade-order-history-menu">
              <p
                onClick={() => handleHistory("PositionHolding")}
                className={historyShow === "PositionHolding" ? "active" : ""}
              >
                Position holding
              </p>
              <p
                onClick={() => handleHistory("PendingOrders")}
                className={historyShow === "PendingOrders" ? "active" : ""}
              >
                Pending Orders
              </p>
              <p
                onClick={() => handleHistory("History")}
                className={historyShow === "History" ? "active" : ""}
              >
                History
              </p>
            </div>
            <div className="trade-order-history-menu-dis">
              <p>
                Profit and Loss:
                {data >= 0 && <span className="profit-up">{parseFloat(data).toFixed(2)}</span>}
                {data < 0 && <span className="profit-dwon">{parseFloat(data).toFixed(2)}</span>}

              </p>
            </div>
          </div>
          {historyShow === "PositionHolding" && <PositionHolding />}
          {historyShow === "PendingOrders" && <PendingOrders />}
          {historyShow === "History" && <History />}
        </section>
      </section>
    </>
  );
};

export default Dashboard;
