import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./UserModalMenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import LogoutConponent from "../../../Auth/Logout/LogoutConponent";
import Deposit from "../Deposit/Deposit";
import Withdrawal from "../Withdrawal/Withdrawal";
import KYC from "../KYC/KYC";
import Assets from "../Assets/Assets";
import UpdateProfile from "../UpdateProfile/UpdateProfile";
import UpdatePassword from "../UpdatePassword/UpdatePassword";
import DepositHistory from "../Deposit/DepositHistory/DepositHistory";
import WithdrawalHistory from "../Withdrawal/WithdrawalHistory/WithdrawalHistory";
const UserModalMenu = (props) => {
  const [showDetails, setShowDetails] = useState("Billing");

  const handleShow = (showItem) => {
    switch (showItem) {
      case "Billing":
        setShowDetails("Billing");
        break;
      case "Deposit":
        setShowDetails("Deposit");
        break;
      case "DepositHistory":
        setShowDetails("DepositHistory");
        break;
      case "Withdrawal":
        setShowDetails("Withdrawal");
        break;
      case "WithdrawalHistory":
        setShowDetails("WithdrawalHistory");
        break;
      case "KYC":
        setShowDetails("KYC");
        break;
      case "Assets":
        setShowDetails("Assets");
        break;
      case "UpdateProfile":
        setShowDetails("UpdateProfile");
        break;
      case "UpdatePassword":
        setShowDetails("UpdatePassword");
        break;

      default:
        break;
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className="user-dashboard-menu-section"
      >
        <Modal.Body>
          <div className="user-dashboard-menu-header">
            <h5>Menu</h5>
            <FontAwesomeIcon icon={faXmark} onClick={props.onHide} />
          </div>

          <div className="user-dashboard-menu-main-area">
            <div className="row">
              <div className="col-3">
                <div className="user-dashboard-menu-main-left">
                  <p  className={showDetails === "Billing" ? "active" : ""} onClick={() => handleShow("Billing")}>
                    Billing Details
                  </p>
                  <p onClick={() => handleShow("Deposit")}  className={showDetails === "Deposit" ? "active" : ""}>Deposit</p>
                  <p onClick={() => handleShow("DepositHistory")}  className={showDetails === "DepositHistory" ? "active" : ""}>Deposit History</p>
                  <p onClick={() => handleShow("Withdrawal")}  className={showDetails === "Withdrawal" ? "active" : ""}>Withdrawal</p>
                  <p onClick={() => handleShow("WithdrawalHistory")}  className={showDetails === "WithdrawalHistory" ? "active" : ""}>Withdrawal History</p>
                  <p onClick={() => handleShow("KYC")}  className={showDetails === "KYC" ? "active" : ""}>KYC</p>
                  <p onClick={() => handleShow("Assets")}  className={showDetails === "Assets" ? "active" : ""}>Assets</p>
                  <p onClick={() => handleShow("UpdateProfile")}  className={showDetails === "UpdateProfile" ? "active" : ""}>Update Profile</p>
                  <p onClick={() => handleShow("UpdatePassword")}  className={showDetails === "UpdatePassword" ? "active" : ""}>Change Password</p>
                  {/* Logout  section*/}
                  <LogoutConponent />
                </div>
              </div>
              <div className="col-9">
                <div className="modal-body-details-area">
                {showDetails === "Billing" && <>Billing Details</>}
                {showDetails === "Deposit" && <> <Deposit />  </>}
                {showDetails === "DepositHistory" && <> <DepositHistory />  </>}
                {showDetails === "Withdrawal" && <> <Withdrawal /> </>}
                {showDetails === "WithdrawalHistory" && <> <WithdrawalHistory /> </>}
                {showDetails === "KYC" && <> <KYC /> </>}
                {showDetails === "Assets" && <> <Assets /> </>}
                {showDetails === "UpdateProfile" && <> <UpdateProfile /> </>}
                {showDetails === "UpdatePassword" && <> <UpdatePassword /> </>}
                </div>
                </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserModalMenu;
