import React from "react";
import ReactPaginate from "react-paginate";
import "./CustomPagination.css"

const CustomPagination = ({data, handlePage}) => {
  
  return (
    <>
      <div className="card-footer py-3 custom-pagination me-5">
        <nav className="d-flex justify-content-end">
        <ReactPaginate
        breakLabel="..."
        nextLabel="Next"
        onPageChange={handlePage}
        pageRangeDisplayed={4}
        pageCount={data?.pageCount}
        previousLabel="Previous"
        renderOnZeroPageCount={null}
        marginPagesDisplayed={1}
        containerClassName="pagination justify-content-center"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        activeClassName="active"
      />
        </nav>
      </div>
    </>
  );
};

export default CustomPagination;
