import React from "react";
const LoanSingleHistoryItem = ({ data, index, paginateData }) => {
  return (
    <>
      <tr>
        <td>{ (parseFloat(index + 1) + (parseFloat(paginateData?.page) * parseFloat(paginateData?.limit))) - 10 }</td>
        <td>{data?.loan_package_name}</td>
        <td>{data?.amount}$</td>
        <td>{data?.total_amount}$</td>
        <td>1</td>

        <td>
         {data?.expired_time}
        </td>
        <td>{data?.status === 2 ? "paid" : ""} {data?.status === 1 ? "unpaid" : ""} </td>
        <td>{data?.status === 0 ? <span class="badge bg-warning ">Pending</span>  : data?.status === 3 ?  <span class="badge bg-danger">Reject</span>  :  <span class="badge bg-primary">Approved</span>} </td>
      </tr>
    </>
  );
};

export default LoanSingleHistoryItem;
