import React, { useContext, useRef, useState } from "react";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import { toast } from "react-toastify";

const UpdatePassword = () => {

    const { authUser } = useContext(AuthContext);
  
    const [errorMessage, setErrorMessage] = useState({});
    const refSubmitDis = useRef();
   
    const handleLogin = (event) => {
      event.preventDefault();
      refSubmitDis.current.setAttribute("disabled", true);
      const formData = new FormData(event.currentTarget);
      const InpuData = Object.fromEntries(formData);
      setErrorMessage();
      fetch(`${process.env.REACT_APP_API}/api/user/auth/password/update/${authUser?._id}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(InpuData),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success === false) {
            if(data?.errors){
              setErrorMessage(data.errors);
            }else{
              setErrorMessage(data);
            }
            
          } else {
            event.target.reset();
            toast.success(`${data.message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
          }
          refSubmitDis.current.removeAttribute("disabled");
        })
        .catch((error) => {
          // console.log(error)
        }
      );
    };

  return (
    <>
      <div className="user-deposit-section">
        <div className="container">
          <div className="card h-100">
            <div className="card-body">
              <form onSubmit={handleLogin}>
                <div className="row gutters">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h6 className="mb-3 text-primary">Change Password</h6>
                  </div>

                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                    <div className="form-group">
                      <label
                        htmlFor="email"
                        className="form-label"
                      >
                       Email
                      </label>
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        id="email"
                        aria-describedby="emailHelp"
                      />
                          {errorMessage?.email &&
                            <span className="text-danger pt-2">
                            {errorMessage?.email?.msg}
                            </span>
                            }
                          {errorMessage?.message &&
                            <span className="text-danger pt-2">
                            {errorMessage?.message}
                            </span>
                            }
                       
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                    <div className="form-group">
                      <label
                        htmlFor="old_password"
                        className="form-label"
                      >
                        Old Password
                      </label>
                      <input
                        type="password"
                        name="old_password"
                        className="form-control"
                        id="old_password"
                        aria-describedby="emailHelp"
                      />
                          {errorMessage?.old_password &&
                            <span className="text-danger pt-2">
                            {errorMessage?.old_password?.msg}
                            </span>
                            }
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                    <div className="form-group">
                      <label
                        htmlFor="password"
                        className="form-label"
                      >
                       Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        id="password"
                        aria-describedby="emailHelp"
                      />
                     {errorMessage?.password &&
                            <span className="text-danger pt-2">
                           { errorMessage?.password?.msg}
                            </span>
                            }
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                    <div className="form-group">
                      <label
                        htmlFor="cpassword"
                        className="form-label"
                      >
                      Confirm Password
                      </label>
                      <input
                        type="password"
                        name="cpassword"
                        className="form-control"
                        id="cpassword"
                        aria-describedby="emailHelp"
                      />
                      {errorMessage?.cpassword &&
                            <span className="text-danger pt-2">
                            {errorMessage?.cpassword?.msg}
                            </span>
                            }
                    </div>
                  </div>
                </div>
                <div className="user-submit-btn">
                  <button
                    type="submit"
                    id="submit"
                    className="btn btn-primary px-5 fw-normal d-block mt-1"
                      ref={refSubmitDis}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePassword;
