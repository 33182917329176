import React, { useEffect, useRef, memo } from "react";
function CFD() {
  const container = useRef({});
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-market-quotes.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
           {
  "width": "100%",
  "height": "100%",
  "symbolsGroups": [
    {
      "name": "CFDs",
      "symbols": [
        {
          "name": "CHINA50",
          "displayName": "CHINA 50"
        },
        {
          "name": "NAS100",
          "displayName": "NAS100"
        },
        {
          "name": "AUS200",
          "displayName": "AUS200"
        },
        {
          "name": "US30",
          "displayName": "US30"
        },
        {
          "name": "JAP225",
          "displayName": "JAP255"
        },
        {
          "name": "SPX500",
          "displayName": "SPX500"
        },
        {
          "name": "GER30",
          "displayName": "GER30"
        }
        
      ]
    }
  ],
"showSymbolLogo": true,
  "isTransparent": false,
  "colorTheme": "dark",
  "locale": "en",
  "backgroundColor": "#0B0619"
}
`;
    container.current.appendChild(script);
  }, []);

  return (
    <>
      <div className="user-market-data">
        <div
          className="tradingview-widget-container"
          ref={container}
          style={{ height: "100%", width: "100%" }}
        >
          <div
            className="tradingview-widget-container__widget"
            style={{ height: "calc(100% - 32px)", width: "100%" }}
          ></div>
        </div>
      </div>
    </>
  );
}

export default memo(CFD);
